import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

export default () => (
  <Layout>
    <SEO title="404: Not found" />
    <div style={{ maxWidth: '1200px', padding: '100px' }}>
      <h1>NOT FOUND</h1>
      <p>This page doesn't seem to exist 😢</p>
      <Link className="link" to="/">
        Head back home
      </Link>
    </div>
  </Layout>
);
